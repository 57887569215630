import { FireButton } from '@nx/fire/button'
import VisuallyHidden from '@nx/fire/visually-hidden'
import { EFollowType, useFollow } from '@nx/get-user-follows'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import { FireStyledIconButton } from './FireFollowButton.styles'
import translate from './FireFollowButton.translate.json'

const { text } = getTranslate(translate)

export function FireFollowButton({
  type,
  itemId,
  title = '',
  metadata,
  variant = 'full',
  baseURL,
  followIcons,
  openLogin,
}: FireFollowButtonProps) {
  const { toggleFollow, isFollowing, isLoading } = useFollow({
    type,
    itemId,
    metadata,
    baseURL,
    openLogin,
  })

  const Icon = isFollowing ? followIcons[0] : followIcons[1]

  return variant === 'simple' ? (
    <FireStyledIconButton onClick={toggleFollow} disabled={isLoading}>
      <Icon />
      <VisuallyHidden>
        {text(isFollowing ? 'following.simple' : 'follow.simple')}&nbsp;
        <span {...SmartlingAttributes.noTranslate}>{title}</span>
      </VisuallyHidden>
    </FireStyledIconButton>
  ) : (
    <FireButton
      variant="ghost"
      startIcon={Icon}
      onClick={toggleFollow}
      disabled={isLoading}
    >
      {text(isFollowing ? 'following.full' : 'follow.full')}
    </FireButton>
  )
}

export interface FireFollowButtonProps {
  type: EFollowType
  title?: string
  itemId: number | string
  baseURL: string
  followIcons: [React.FunctionComponent, React.FunctionComponent]
  variant?: 'simple' | 'full'
  metadata?: {
    auctionId?: number | string
  }
  openLogin?: () => void
}
